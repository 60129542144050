// eslint-disable-next-line
import {
  AuthApi as AuthAPI,
  Configuration,
  UsersApi as UsersAPI,
  ResultsApi as ResultsAPI,
  WorkNamesApi as WorkNamesAPI,
  WorkGroupsApi as WorkGroupsAPI,
  CarsApi as CarsAPI,
  WorksApi as WorksAPI,
  WorkDetailsApi as WorkDetailsAPI,
  CategoriesApi as CategoriesAPI,
} from "@/api";
import TokenUtil from "@/utils/TokenUtil";
import { URLS } from "@/constants";

export class AuthApi extends AuthAPI {
  protected static _instance: AuthApi;

  public static get instance(): AuthApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): AuthAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}

export class UsersApi extends UsersAPI {
  protected static _instance: UsersApi;

  public static get instance(): UsersApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): UsersAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}

export class ResultsApi extends ResultsAPI {
  protected static _instance: ResultsApi;

  public static get instance(): ResultsApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): ResultsAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}

export class WorkNamesApi extends WorkNamesAPI {
  protected static _instance: WorkNamesApi;

  public static get instance(): WorkNamesApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): WorkNamesAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}

export class WorkGroupsApi extends WorkGroupsAPI {
  protected static _instance: WorkGroupsApi;

  public static get instance(): WorkGroupsApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): WorkGroupsAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}

export class WorksApi extends WorksAPI {
  protected static _instance: WorksApi;

  public static get instance(): WorksApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): WorksAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}

export class CarsApi extends CarsAPI {
  protected static _instance: CarsApi;

  public static get instance(): CarsApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): CarsAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}
export class WorkDetailsApi extends WorkDetailsAPI {
  protected static _instance: WorkDetailsApi;

  public static get instance(): WorkDetailsApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): WorkDetailsAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}
export class CategoriesApi extends CategoriesAPI {
  protected static _instance: CategoriesApi;

  public static get instance(): CategoriesApi {
    return this.createInstance();
  }

  public static createInstance(_token?: string): CategoriesAPI {
    if (_token) {
      const config = new Configuration({
        accessToken: _token,
        basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
      });
      this._instance = new this(config);
    } else {
      if (this._instance == null) {
        const token = TokenUtil.getToken();
        if (this._instance == null && token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        } else {
          // NOTE: 何かしらconfigration optionを入れないとconfigが生成されない
          const config = new Configuration({
            username: "enduser",
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
      if (!this._instance.configuration?.apiKey) {
        const token = TokenUtil.getToken();
        if (token) {
          const config = new Configuration({
            accessToken: token,
            basePath: `${URLS.API_BASE_URL}${URLS.API_VERSION}`,
          });
          this._instance = new this(config);
        }
      }
    }
    return this._instance;
  }
}
