/* tslint:disable */
/* eslint-disable */
/**
 * Yamauchi Automobile API
 * Yamauchi Automobile Apiサーバースキーマ
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Car
 */
export interface Car {
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    carName?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    carNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    mileage?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Car
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Car
     */
    deleted?: boolean;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    deleted?: boolean;
}
/**
 * 
 * @export
 * @interface EnvelopedCar
 */
export interface EnvelopedCar {
    /**
     * 
     * @type {EnvelopedCarData}
     * @memberof EnvelopedCar
     */
    data?: EnvelopedCarData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedCar
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedCarData
 */
export interface EnvelopedCarData {
    /**
     * 
     * @type {Car}
     * @memberof EnvelopedCarData
     */
    car?: Car;
}
/**
 * 
 * @export
 * @interface EnvelopedCategory
 */
export interface EnvelopedCategory {
    /**
     * 
     * @type {EnvelopedCategoryData}
     * @memberof EnvelopedCategory
     */
    data?: EnvelopedCategoryData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedCategory
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedCategoryData
 */
export interface EnvelopedCategoryData {
    /**
     * 
     * @type {Category}
     * @memberof EnvelopedCategoryData
     */
    category?: Category;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedCarList
 */
export interface EnvelopedPaginatedCarList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedCarList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedCarList}
     * @memberof EnvelopedPaginatedCarList
     */
    data?: PaginatedCarList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedCategoryList
 */
export interface EnvelopedPaginatedCategoryList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedCategoryList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedCategoryList}
     * @memberof EnvelopedPaginatedCategoryList
     */
    data?: PaginatedCategoryList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedResultList
 */
export interface EnvelopedPaginatedResultList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedResultList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedResultList}
     * @memberof EnvelopedPaginatedResultList
     */
    data?: PaginatedResultList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedUserList
 */
export interface EnvelopedPaginatedUserList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedUserList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedUserList}
     * @memberof EnvelopedPaginatedUserList
     */
    data?: PaginatedUserList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedWorkDetailList
 */
export interface EnvelopedPaginatedWorkDetailList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedWorkDetailList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedWorkDetailList}
     * @memberof EnvelopedPaginatedWorkDetailList
     */
    data?: PaginatedWorkDetailList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedWorkGroupList
 */
export interface EnvelopedPaginatedWorkGroupList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedWorkGroupList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedWorkGroupList}
     * @memberof EnvelopedPaginatedWorkGroupList
     */
    data?: PaginatedWorkGroupList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedWorkList
 */
export interface EnvelopedPaginatedWorkList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedWorkList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedWorkList}
     * @memberof EnvelopedPaginatedWorkList
     */
    data?: PaginatedWorkList;
}
/**
 * 
 * @export
 * @interface EnvelopedPaginatedWorkNameList
 */
export interface EnvelopedPaginatedWorkNameList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedPaginatedWorkNameList
     */
    status?: string;
    /**
     * 
     * @type {PaginatedWorkNameList}
     * @memberof EnvelopedPaginatedWorkNameList
     */
    data?: PaginatedWorkNameList;
}
/**
 * 
 * @export
 * @interface EnvelopedResult
 */
export interface EnvelopedResult {
    /**
     * 
     * @type {EnvelopedResultData}
     * @memberof EnvelopedResult
     */
    data?: EnvelopedResultData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedResult
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedResultData
 */
export interface EnvelopedResultData {
    /**
     * 
     * @type {Result}
     * @memberof EnvelopedResultData
     */
    result?: Result;
}
/**
 * 
 * @export
 * @interface EnvelopedToken
 */
export interface EnvelopedToken {
    /**
     * 
     * @type {Token}
     * @memberof EnvelopedToken
     */
    data?: Token;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedToken
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedUser
 */
export interface EnvelopedUser {
    /**
     * 
     * @type {EnvelopedUserData}
     * @memberof EnvelopedUser
     */
    data?: EnvelopedUserData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedUser
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedUserAndToken
 */
export interface EnvelopedUserAndToken {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedUserAndToken
     */
    status?: string;
    /**
     * 
     * @type {EnvelopedUserAndTokenData}
     * @memberof EnvelopedUserAndToken
     */
    data?: EnvelopedUserAndTokenData;
}
/**
 * 
 * @export
 * @interface EnvelopedUserAndTokenData
 */
export interface EnvelopedUserAndTokenData {
    /**
     * 
     * @type {User}
     * @memberof EnvelopedUserAndTokenData
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedUserAndTokenData
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedUserData
 */
export interface EnvelopedUserData {
    /**
     * 
     * @type {User}
     * @memberof EnvelopedUserData
     */
    user?: User;
}
/**
 * 
 * @export
 * @interface EnvelopedWork
 */
export interface EnvelopedWork {
    /**
     * 
     * @type {EnvelopedWorkData}
     * @memberof EnvelopedWork
     */
    data?: EnvelopedWorkData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedWork
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkData
 */
export interface EnvelopedWorkData {
    /**
     * 
     * @type {Work}
     * @memberof EnvelopedWorkData
     */
    work?: Work;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkDetail
 */
export interface EnvelopedWorkDetail {
    /**
     * 
     * @type {EnvelopedWorkDetailData}
     * @memberof EnvelopedWorkDetail
     */
    data?: EnvelopedWorkDetailData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedWorkDetail
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkDetailData
 */
export interface EnvelopedWorkDetailData {
    /**
     * 
     * @type {WorkDetail}
     * @memberof EnvelopedWorkDetailData
     */
    workDetail?: WorkDetail;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkDetailList
 */
export interface EnvelopedWorkDetailList {
    /**
     * 
     * @type {string}
     * @memberof EnvelopedWorkDetailList
     */
    status?: string;
    /**
     * 
     * @type {Array<WorkDetail>}
     * @memberof EnvelopedWorkDetailList
     */
    data?: Array<WorkDetail>;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkGroup
 */
export interface EnvelopedWorkGroup {
    /**
     * 
     * @type {EnvelopedWorkGroupData}
     * @memberof EnvelopedWorkGroup
     */
    data?: EnvelopedWorkGroupData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedWorkGroup
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkGroupData
 */
export interface EnvelopedWorkGroupData {
    /**
     * 
     * @type {WorkGroup}
     * @memberof EnvelopedWorkGroupData
     */
    workGroup?: WorkGroup;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkName
 */
export interface EnvelopedWorkName {
    /**
     * 
     * @type {EnvelopedWorkNameData}
     * @memberof EnvelopedWorkName
     */
    data?: EnvelopedWorkNameData;
    /**
     * 
     * @type {string}
     * @memberof EnvelopedWorkName
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface EnvelopedWorkNameData
 */
export interface EnvelopedWorkNameData {
    /**
     * 
     * @type {WorkName}
     * @memberof EnvelopedWorkNameData
     */
    workName?: WorkName;
}
/**
 * 
 * @export
 * @interface LoginBody
 */
export interface LoginBody {
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    password: string;
}
/**
 * 
 * @export
 * @interface NewCar
 */
export interface NewCar {
    /**
     * 
     * @type {string}
     * @memberof NewCar
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCar
     */
    carName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCar
     */
    carNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCar
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCar
     */
    mileage?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCar
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface NewCategory
 */
export interface NewCategory {
    /**
     * 
     * @type {string}
     * @memberof NewCategory
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewCategory
     */
    deleted?: boolean;
}
/**
 * 
 * @export
 * @interface NewResult
 */
export interface NewResult {
    /**
     * 
     * @type {string}
     * @memberof NewResult
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewResult
     */
    deleted?: boolean;
}
/**
 * 
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof NewUser
     */
    userRole?: number;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface NewWork
 */
export interface NewWork {
    /**
     * 
     * @type {string}
     * @memberof NewWork
     */
    carUuid?: string;
}
/**
 * 
 * @export
 * @interface NewWorkDetail
 */
export interface NewWorkDetail {
    /**
     * 
     * @type {string}
     * @memberof NewWorkDetail
     */
    workId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWorkDetail
     */
    workNameId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWorkDetail
     */
    resultId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewWorkDetail
     */
    order?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewWorkDetail
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof NewWorkDetail
     */
    targetTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NewWorkDetail
     */
    workedTime?: number | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof NewWorkDetail
     */
    users?: Array<User>;
}
/**
 * 
 * @export
 * @interface NewWorkGroup
 */
export interface NewWorkGroup {
    /**
     * 
     * @type {string}
     * @memberof NewWorkGroup
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWorkGroup
     */
    categoryId?: string | null;
    /**
     * 
     * @type {Array<WorkName>}
     * @memberof NewWorkGroup
     */
    workNames?: Array<WorkName>;
}
/**
 * 
 * @export
 * @interface NewWorkName
 */
export interface NewWorkName {
    /**
     * 
     * @type {string}
     * @memberof NewWorkName
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewWorkName
     */
    permanence?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewWorkName
     */
    categoryId?: string | null;
}
/**
 * 
 * @export
 * @interface PaginatedCarList
 */
export interface PaginatedCarList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedCarList
     */
    total?: number;
    /**
     * 
     * @type {Array<Car>}
     * @memberof PaginatedCarList
     */
    items?: Array<Car>;
}
/**
 * 
 * @export
 * @interface PaginatedCategoryList
 */
export interface PaginatedCategoryList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedCategoryList
     */
    total?: number;
    /**
     * 
     * @type {Array<Category>}
     * @memberof PaginatedCategoryList
     */
    items?: Array<Category>;
}
/**
 * 
 * @export
 * @interface PaginatedResultList
 */
export interface PaginatedResultList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResultList
     */
    total?: number;
    /**
     * 
     * @type {Array<Result>}
     * @memberof PaginatedResultList
     */
    items?: Array<Result>;
}
/**
 * 
 * @export
 * @interface PaginatedUserList
 */
export interface PaginatedUserList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedUserList
     */
    total?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof PaginatedUserList
     */
    items?: Array<User>;
}
/**
 * 
 * @export
 * @interface PaginatedWorkDetailList
 */
export interface PaginatedWorkDetailList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWorkDetailList
     */
    total?: number;
    /**
     * 
     * @type {Array<WorkDetail>}
     * @memberof PaginatedWorkDetailList
     */
    items?: Array<WorkDetail>;
}
/**
 * 
 * @export
 * @interface PaginatedWorkGroupList
 */
export interface PaginatedWorkGroupList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWorkGroupList
     */
    total?: number;
    /**
     * 
     * @type {Array<WorkGroup>}
     * @memberof PaginatedWorkGroupList
     */
    items?: Array<WorkGroup>;
}
/**
 * 
 * @export
 * @interface PaginatedWorkList
 */
export interface PaginatedWorkList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWorkList
     */
    total?: number;
    /**
     * 
     * @type {Array<Work>}
     * @memberof PaginatedWorkList
     */
    items?: Array<Work>;
}
/**
 * 
 * @export
 * @interface PaginatedWorkNameList
 */
export interface PaginatedWorkNameList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWorkNameList
     */
    total?: number;
    /**
     * 
     * @type {Array<WorkName>}
     * @memberof PaginatedWorkNameList
     */
    items?: Array<WorkName>;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    deleted?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortDirection {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUser
     */
    userRole?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUser
     */
    adminStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    userRole?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface Work
 */
export interface Work {
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    carUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    finishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Work
     */
    deleted?: boolean;
    /**
     * 
     * @type {Car}
     * @memberof Work
     */
    car?: Car;
    /**
     * 
     * @type {Array<WorkDetail>}
     * @memberof Work
     */
    workDetails?: Array<WorkDetail>;
}
/**
 * 
 * @export
 * @interface WorkDetail
 */
export interface WorkDetail {
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    workId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    workNameId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    resultId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkDetail
     */
    order?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkDetail
     */
    targetTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkDetail
     */
    workedTime?: number | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof WorkDetail
     */
    users?: Array<User>;
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDetail
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkDetail
     */
    deleted?: boolean;
    /**
     * 
     * @type {Result}
     * @memberof WorkDetail
     */
    result?: Result;
    /**
     * 
     * @type {WorkName}
     * @memberof WorkDetail
     */
    workName?: WorkName;
}
/**
 * 
 * @export
 * @interface WorkGroup
 */
export interface WorkGroup {
    /**
     * 
     * @type {string}
     * @memberof WorkGroup
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGroup
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGroup
     */
    categoryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkGroup
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGroup
     */
    updatedAt?: string;
    /**
     * 
     * @type {Array<WorkName>}
     * @memberof WorkGroup
     */
    workNames?: Array<WorkName>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkGroup
     */
    deleted?: boolean;
    /**
     * 
     * @type {Category}
     * @memberof WorkGroup
     */
    category?: Category;
}
/**
 * 
 * @export
 * @interface WorkName
 */
export interface WorkName {
    /**
     * 
     * @type {string}
     * @memberof WorkName
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkName
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkName
     */
    categoryId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkName
     */
    permanence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkName
     */
    deleted?: boolean;
    /**
     * 
     * @type {Category}
     * @memberof WorkName
     */
    category?: Category;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkStatus {
    NotStarted = 'notStarted',
    Working = 'working',
    Completed = 'completed'
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User login
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin: async (loginBody: LoginBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginBody' is not null or undefined
            if (loginBody === null || loginBody === undefined) {
                throw new RequiredError('loginBody','Required parameter loginBody was null or undefined when calling userLogin.');
            }
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginBody !== undefined ? loginBody : {})
                : (loginBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedUser>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getMe(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary User login
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogin(loginBody: LoginBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedUserAndToken>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).userLogin(loginBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<EnvelopedUser> {
            return AuthApiFp(configuration).getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User login
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin(loginBody: LoginBody, options?: any): AxiosPromise<EnvelopedUserAndToken> {
            return AuthApiFp(configuration).userLogin(loginBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Get me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getMe(options?: any) {
        return AuthApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User login
     * @param {LoginBody} loginBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public userLogin(loginBody: LoginBody, options?: any) {
        return AuthApiFp(this.configuration).userLogin(loginBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarsApi - axios parameter creator
 * @export
 */
export const CarsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create car
         * @param {NewCar} newCar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCar: async (newCar: NewCar, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newCar' is not null or undefined
            if (newCar === null || newCar === undefined) {
                throw new RequiredError('newCar','Required parameter newCar was null or undefined when calling createCar.');
            }
            const localVarPath = `/cars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newCar !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newCar !== undefined ? newCar : {})
                : (newCar || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete car by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteCarByUuid.');
            }
            const localVarPath = `/cars/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get car by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarbyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getCarbyUuid.');
            }
            const localVarPath = `/cars/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cars
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCars: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update car by uuid
         * @param {string} uuid 
         * @param {Car} car 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarByUuid: async (uuid: string, car: Car, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateCarByUuid.');
            }
            // verify required parameter 'car' is not null or undefined
            if (car === null || car === undefined) {
                throw new RequiredError('car','Required parameter car was null or undefined when calling updateCarByUuid.');
            }
            const localVarPath = `/cars/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof car !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(car !== undefined ? car : {})
                : (car || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarsApi - functional programming interface
 * @export
 */
export const CarsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create car
         * @param {NewCar} newCar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCar(newCar: NewCar, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCar>> {
            const localVarAxiosArgs = await CarsApiAxiosParamCreator(configuration).createCar(newCar, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete car by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCarByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCar>> {
            const localVarAxiosArgs = await CarsApiAxiosParamCreator(configuration).deleteCarByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get car by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarbyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCar>> {
            const localVarAxiosArgs = await CarsApiAxiosParamCreator(configuration).getCarbyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get cars
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCars(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedCarList>> {
            const localVarAxiosArgs = await CarsApiAxiosParamCreator(configuration).getCars(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update car by uuid
         * @param {string} uuid 
         * @param {Car} car 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCarByUuid(uuid: string, car: Car, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCar>> {
            const localVarAxiosArgs = await CarsApiAxiosParamCreator(configuration).updateCarByUuid(uuid, car, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CarsApi - factory interface
 * @export
 */
export const CarsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create car
         * @param {NewCar} newCar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCar(newCar: NewCar, options?: any): AxiosPromise<EnvelopedCar> {
            return CarsApiFp(configuration).createCar(newCar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete car by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedCar> {
            return CarsApiFp(configuration).deleteCarByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get car by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarbyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedCar> {
            return CarsApiFp(configuration).getCarbyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cars
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCars(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedCarList> {
            return CarsApiFp(configuration).getCars(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update car by uuid
         * @param {string} uuid 
         * @param {Car} car 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarByUuid(uuid: string, car: Car, options?: any): AxiosPromise<EnvelopedCar> {
            return CarsApiFp(configuration).updateCarByUuid(uuid, car, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarsApi - object-oriented interface
 * @export
 * @class CarsApi
 * @extends {BaseAPI}
 */
export class CarsApi extends BaseAPI {
    /**
     * 
     * @summary Create car
     * @param {NewCar} newCar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarsApi
     */
    public createCar(newCar: NewCar, options?: any) {
        return CarsApiFp(this.configuration).createCar(newCar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete car by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarsApi
     */
    public deleteCarByUuid(uuid: string, options?: any) {
        return CarsApiFp(this.configuration).deleteCarByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get car by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarsApi
     */
    public getCarbyUuid(uuid: string, options?: any) {
        return CarsApiFp(this.configuration).getCarbyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cars
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarsApi
     */
    public getCars(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return CarsApiFp(this.configuration).getCars(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update car by uuid
     * @param {string} uuid 
     * @param {Car} car 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarsApi
     */
    public updateCarByUuid(uuid: string, car: Car, options?: any) {
        return CarsApiFp(this.configuration).updateCarByUuid(uuid, car, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create category
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (newCategory: NewCategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newCategory' is not null or undefined
            if (newCategory === null || newCategory === undefined) {
                throw new RequiredError('newCategory','Required parameter newCategory was null or undefined when calling createCategory.');
            }
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newCategory !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newCategory !== undefined ? newCategory : {})
                : (newCategory || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete category by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteCategoryByUuid.');
            }
            const localVarPath = `/categories/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get categories
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorybyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getCategorybyUuid.');
            }
            const localVarPath = `/categories/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update category by uuid
         * @param {string} uuid 
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryByUuid: async (uuid: string, category: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateCategoryByUuid.');
            }
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling updateCategoryByUuid.');
            }
            const localVarPath = `/categories/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof category !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(category !== undefined ? category : {})
                : (category || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create category
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(newCategory: NewCategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCategory>> {
            const localVarAxiosArgs = await CategoriesApiAxiosParamCreator(configuration).createCategory(newCategory, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete category by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategoryByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCategory>> {
            const localVarAxiosArgs = await CategoriesApiAxiosParamCreator(configuration).deleteCategoryByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get categories
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedCategoryList>> {
            const localVarAxiosArgs = await CategoriesApiAxiosParamCreator(configuration).getCategories(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get category by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategorybyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCategory>> {
            const localVarAxiosArgs = await CategoriesApiAxiosParamCreator(configuration).getCategorybyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update category by uuid
         * @param {string} uuid 
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategoryByUuid(uuid: string, category: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedCategory>> {
            const localVarAxiosArgs = await CategoriesApiAxiosParamCreator(configuration).updateCategoryByUuid(uuid, category, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create category
         * @param {NewCategory} newCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(newCategory: NewCategory, options?: any): AxiosPromise<EnvelopedCategory> {
            return CategoriesApiFp(configuration).createCategory(newCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete category by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedCategory> {
            return CategoriesApiFp(configuration).deleteCategoryByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get categories
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedCategoryList> {
            return CategoriesApiFp(configuration).getCategories(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get category by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorybyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedCategory> {
            return CategoriesApiFp(configuration).getCategorybyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update category by uuid
         * @param {string} uuid 
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryByUuid(uuid: string, category: Category, options?: any): AxiosPromise<EnvelopedCategory> {
            return CategoriesApiFp(configuration).updateCategoryByUuid(uuid, category, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Create category
     * @param {NewCategory} newCategory 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public createCategory(newCategory: NewCategory, options?: any) {
        return CategoriesApiFp(this.configuration).createCategory(newCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete category by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public deleteCategoryByUuid(uuid: string, options?: any) {
        return CategoriesApiFp(this.configuration).deleteCategoryByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get categories
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getCategories(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return CategoriesApiFp(this.configuration).getCategories(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get category by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getCategorybyUuid(uuid: string, options?: any) {
        return CategoriesApiFp(this.configuration).getCategorybyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update category by uuid
     * @param {string} uuid 
     * @param {Category} category 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public updateCategoryByUuid(uuid: string, category: Category, options?: any) {
        return CategoriesApiFp(this.configuration).updateCategoryByUuid(uuid, category, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Communication check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Communication check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).communicationCheck(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Communication check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationCheck(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).communicationCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Communication check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public communicationCheck(options?: any) {
        return DefaultApiFp(this.configuration).communicationCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResultsApi - axios parameter creator
 * @export
 */
export const ResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create result
         * @param {NewResult} newResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResult: async (newResult: NewResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newResult' is not null or undefined
            if (newResult === null || newResult === undefined) {
                throw new RequiredError('newResult','Required parameter newResult was null or undefined when calling createResult.');
            }
            const localVarPath = `/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newResult !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newResult !== undefined ? newResult : {})
                : (newResult || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete result by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResultByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteResultByUuid.');
            }
            const localVarPath = `/results/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get result by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultbyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getResultbyUuid.');
            }
            const localVarPath = `/results/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get results
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update result by uuid
         * @param {string} uuid 
         * @param {Result} result 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResultByUuid: async (uuid: string, result: Result, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateResultByUuid.');
            }
            // verify required parameter 'result' is not null or undefined
            if (result === null || result === undefined) {
                throw new RequiredError('result','Required parameter result was null or undefined when calling updateResultByUuid.');
            }
            const localVarPath = `/results/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof result !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(result !== undefined ? result : {})
                : (result || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResultsApi - functional programming interface
 * @export
 */
export const ResultsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create result
         * @param {NewResult} newResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResult(newResult: NewResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedResult>> {
            const localVarAxiosArgs = await ResultsApiAxiosParamCreator(configuration).createResult(newResult, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete result by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResultByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedResult>> {
            const localVarAxiosArgs = await ResultsApiAxiosParamCreator(configuration).deleteResultByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get result by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResultbyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedResult>> {
            const localVarAxiosArgs = await ResultsApiAxiosParamCreator(configuration).getResultbyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get results
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResults(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedResultList>> {
            const localVarAxiosArgs = await ResultsApiAxiosParamCreator(configuration).getResults(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update result by uuid
         * @param {string} uuid 
         * @param {Result} result 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResultByUuid(uuid: string, result: Result, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedResult>> {
            const localVarAxiosArgs = await ResultsApiAxiosParamCreator(configuration).updateResultByUuid(uuid, result, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResultsApi - factory interface
 * @export
 */
export const ResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create result
         * @param {NewResult} newResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResult(newResult: NewResult, options?: any): AxiosPromise<EnvelopedResult> {
            return ResultsApiFp(configuration).createResult(newResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete result by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResultByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedResult> {
            return ResultsApiFp(configuration).deleteResultByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get result by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultbyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedResult> {
            return ResultsApiFp(configuration).getResultbyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get results
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedResultList> {
            return ResultsApiFp(configuration).getResults(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update result by uuid
         * @param {string} uuid 
         * @param {Result} result 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResultByUuid(uuid: string, result: Result, options?: any): AxiosPromise<EnvelopedResult> {
            return ResultsApiFp(configuration).updateResultByUuid(uuid, result, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResultsApi - object-oriented interface
 * @export
 * @class ResultsApi
 * @extends {BaseAPI}
 */
export class ResultsApi extends BaseAPI {
    /**
     * 
     * @summary Create result
     * @param {NewResult} newResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public createResult(newResult: NewResult, options?: any) {
        return ResultsApiFp(this.configuration).createResult(newResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete result by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public deleteResultByUuid(uuid: string, options?: any) {
        return ResultsApiFp(this.configuration).deleteResultByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get result by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public getResultbyUuid(uuid: string, options?: any) {
        return ResultsApiFp(this.configuration).getResultbyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get results
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public getResults(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return ResultsApiFp(this.configuration).getResults(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update result by uuid
     * @param {string} uuid 
     * @param {Result} result 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultsApi
     */
    public updateResultByUuid(uuid: string, result: Result, options?: any) {
        return ResultsApiFp(this.configuration).updateResultByUuid(uuid, result, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create user
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (newUser: NewUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newUser' is not null or undefined
            if (newUser === null || newUser === undefined) {
                throw new RequiredError('newUser','Required parameter newUser was null or undefined when calling createUser.');
            }
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newUser !== undefined ? newUser : {})
                : (newUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteUserByUuid.');
            }
            const localVarPath = `/users/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserbyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getUserbyUuid.');
            }
            const localVarPath = `/users/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user by uuid
         * @param {string} uuid 
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserByUuid: async (uuid: string, updateUser: UpdateUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateUserByUuid.');
            }
            // verify required parameter 'updateUser' is not null or undefined
            if (updateUser === null || updateUser === undefined) {
                throw new RequiredError('updateUser','Required parameter updateUser was null or undefined when calling updateUserByUuid.');
            }
            const localVarPath = `/users/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateUser !== undefined ? updateUser : {})
                : (updateUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create user
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(newUser: NewUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedUser>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).createUser(newUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete user by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedUser>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).deleteUserByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserbyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedUser>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getUserbyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get users
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedUserList>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getUsers(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user by uuid
         * @param {string} uuid 
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserByUuid(uuid: string, updateUser: UpdateUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedUser>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).updateUserByUuid(uuid, updateUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create user
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(newUser: NewUser, options?: any): AxiosPromise<EnvelopedUser> {
            return UsersApiFp(configuration).createUser(newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedUser> {
            return UsersApiFp(configuration).deleteUserByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserbyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedUser> {
            return UsersApiFp(configuration).getUserbyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedUserList> {
            return UsersApiFp(configuration).getUsers(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user by uuid
         * @param {string} uuid 
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserByUuid(uuid: string, updateUser: UpdateUser, options?: any): AxiosPromise<EnvelopedUser> {
            return UsersApiFp(configuration).updateUserByUuid(uuid, updateUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Create user
     * @param {NewUser} newUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(newUser: NewUser, options?: any) {
        return UsersApiFp(this.configuration).createUser(newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserByUuid(uuid: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUserByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserbyUuid(uuid: string, options?: any) {
        return UsersApiFp(this.configuration).getUserbyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return UsersApiFp(this.configuration).getUsers(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user by uuid
     * @param {string} uuid 
     * @param {UpdateUser} updateUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserByUuid(uuid: string, updateUser: UpdateUser, options?: any) {
        return UsersApiFp(this.configuration).updateUserByUuid(uuid, updateUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkDetailsApi - axios parameter creator
 * @export
 */
export const WorkDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create workDetail
         * @param {NewWorkDetail} newWorkDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkDetail: async (newWorkDetail: NewWorkDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newWorkDetail' is not null or undefined
            if (newWorkDetail === null || newWorkDetail === undefined) {
                throw new RequiredError('newWorkDetail','Required parameter newWorkDetail was null or undefined when calling createWorkDetail.');
            }
            const localVarPath = `/workDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newWorkDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newWorkDetail !== undefined ? newWorkDetail : {})
                : (newWorkDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete workDetail by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkDetailByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteWorkDetailByUuid.');
            }
            const localVarPath = `/workDetails/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workDetail by nameUuid
         * @param {string} nameUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetailbyNameUuid: async (nameUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nameUuid' is not null or undefined
            if (nameUuid === null || nameUuid === undefined) {
                throw new RequiredError('nameUuid','Required parameter nameUuid was null or undefined when calling getWorkDetailbyNameUuid.');
            }
            const localVarPath = `/workDetails/workName/ranking/{nameUuid}`
                .replace(`{${"nameUuid"}}`, encodeURIComponent(String(nameUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workDetail by userUuid
         * @param {string} userUuid 
         * @param {string} nameUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetailbyUserUuid: async (userUuid: string, nameUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            if (userUuid === null || userUuid === undefined) {
                throw new RequiredError('userUuid','Required parameter userUuid was null or undefined when calling getWorkDetailbyUserUuid.');
            }
            // verify required parameter 'nameUuid' is not null or undefined
            if (nameUuid === null || nameUuid === undefined) {
                throw new RequiredError('nameUuid','Required parameter nameUuid was null or undefined when calling getWorkDetailbyUserUuid.');
            }
            const localVarPath = `/workDetails/fastest/{userUuid}/{nameUuid}`
                .replace(`{${"userUuid"}}`, encodeURIComponent(String(userUuid)))
                .replace(`{${"nameUuid"}}`, encodeURIComponent(String(nameUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workDetail by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetailbyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getWorkDetailbyUuid.');
            }
            const localVarPath = `/workDetails/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workDetails
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetails: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update workDetail by uuid
         * @param {string} uuid 
         * @param {WorkDetail} workDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDetailByUuid: async (uuid: string, workDetail: WorkDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateWorkDetailByUuid.');
            }
            // verify required parameter 'workDetail' is not null or undefined
            if (workDetail === null || workDetail === undefined) {
                throw new RequiredError('workDetail','Required parameter workDetail was null or undefined when calling updateWorkDetailByUuid.');
            }
            const localVarPath = `/workDetails/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workDetail !== undefined ? workDetail : {})
                : (workDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkDetailsApi - functional programming interface
 * @export
 */
export const WorkDetailsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create workDetail
         * @param {NewWorkDetail} newWorkDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkDetail(newWorkDetail: NewWorkDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkDetail>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).createWorkDetail(newWorkDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete workDetail by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkDetailByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkDetail>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).deleteWorkDetailByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workDetail by nameUuid
         * @param {string} nameUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkDetailbyNameUuid(nameUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkDetailList>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).getWorkDetailbyNameUuid(nameUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workDetail by userUuid
         * @param {string} userUuid 
         * @param {string} nameUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkDetailbyUserUuid(userUuid: string, nameUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkDetail>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).getWorkDetailbyUserUuid(userUuid, nameUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workDetail by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkDetailbyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkDetail>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).getWorkDetailbyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workDetails
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkDetails(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedWorkDetailList>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).getWorkDetails(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update workDetail by uuid
         * @param {string} uuid 
         * @param {WorkDetail} workDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkDetailByUuid(uuid: string, workDetail: WorkDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkDetail>> {
            const localVarAxiosArgs = await WorkDetailsApiAxiosParamCreator(configuration).updateWorkDetailByUuid(uuid, workDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkDetailsApi - factory interface
 * @export
 */
export const WorkDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create workDetail
         * @param {NewWorkDetail} newWorkDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkDetail(newWorkDetail: NewWorkDetail, options?: any): AxiosPromise<EnvelopedWorkDetail> {
            return WorkDetailsApiFp(configuration).createWorkDetail(newWorkDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete workDetail by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkDetailByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWorkDetail> {
            return WorkDetailsApiFp(configuration).deleteWorkDetailByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workDetail by nameUuid
         * @param {string} nameUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetailbyNameUuid(nameUuid: string, options?: any): AxiosPromise<EnvelopedWorkDetailList> {
            return WorkDetailsApiFp(configuration).getWorkDetailbyNameUuid(nameUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workDetail by userUuid
         * @param {string} userUuid 
         * @param {string} nameUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetailbyUserUuid(userUuid: string, nameUuid: string, options?: any): AxiosPromise<EnvelopedWorkDetail> {
            return WorkDetailsApiFp(configuration).getWorkDetailbyUserUuid(userUuid, nameUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workDetail by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetailbyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWorkDetail> {
            return WorkDetailsApiFp(configuration).getWorkDetailbyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workDetails
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkDetails(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedWorkDetailList> {
            return WorkDetailsApiFp(configuration).getWorkDetails(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update workDetail by uuid
         * @param {string} uuid 
         * @param {WorkDetail} workDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDetailByUuid(uuid: string, workDetail: WorkDetail, options?: any): AxiosPromise<EnvelopedWorkDetail> {
            return WorkDetailsApiFp(configuration).updateWorkDetailByUuid(uuid, workDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkDetailsApi - object-oriented interface
 * @export
 * @class WorkDetailsApi
 * @extends {BaseAPI}
 */
export class WorkDetailsApi extends BaseAPI {
    /**
     * 
     * @summary Create workDetail
     * @param {NewWorkDetail} newWorkDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public createWorkDetail(newWorkDetail: NewWorkDetail, options?: any) {
        return WorkDetailsApiFp(this.configuration).createWorkDetail(newWorkDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete workDetail by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public deleteWorkDetailByUuid(uuid: string, options?: any) {
        return WorkDetailsApiFp(this.configuration).deleteWorkDetailByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workDetail by nameUuid
     * @param {string} nameUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public getWorkDetailbyNameUuid(nameUuid: string, options?: any) {
        return WorkDetailsApiFp(this.configuration).getWorkDetailbyNameUuid(nameUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workDetail by userUuid
     * @param {string} userUuid 
     * @param {string} nameUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public getWorkDetailbyUserUuid(userUuid: string, nameUuid: string, options?: any) {
        return WorkDetailsApiFp(this.configuration).getWorkDetailbyUserUuid(userUuid, nameUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workDetail by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public getWorkDetailbyUuid(uuid: string, options?: any) {
        return WorkDetailsApiFp(this.configuration).getWorkDetailbyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workDetails
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public getWorkDetails(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return WorkDetailsApiFp(this.configuration).getWorkDetails(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update workDetail by uuid
     * @param {string} uuid 
     * @param {WorkDetail} workDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkDetailsApi
     */
    public updateWorkDetailByUuid(uuid: string, workDetail: WorkDetail, options?: any) {
        return WorkDetailsApiFp(this.configuration).updateWorkDetailByUuid(uuid, workDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkGroupsApi - axios parameter creator
 * @export
 */
export const WorkGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create workGroup
         * @param {NewWorkGroup} newWorkGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkGroup: async (newWorkGroup: NewWorkGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newWorkGroup' is not null or undefined
            if (newWorkGroup === null || newWorkGroup === undefined) {
                throw new RequiredError('newWorkGroup','Required parameter newWorkGroup was null or undefined when calling createWorkGroup.');
            }
            const localVarPath = `/workGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newWorkGroup !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newWorkGroup !== undefined ? newWorkGroup : {})
                : (newWorkGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete workGroup by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkGroupByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteWorkGroupByUuid.');
            }
            const localVarPath = `/workGroups/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workGroup by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkGroupbyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getWorkGroupbyUuid.');
            }
            const localVarPath = `/workGroups/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workGroups
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkGroups: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update workGroup by uuid
         * @param {string} uuid 
         * @param {WorkGroup} workGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkGroupByUuid: async (uuid: string, workGroup: WorkGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateWorkGroupByUuid.');
            }
            // verify required parameter 'workGroup' is not null or undefined
            if (workGroup === null || workGroup === undefined) {
                throw new RequiredError('workGroup','Required parameter workGroup was null or undefined when calling updateWorkGroupByUuid.');
            }
            const localVarPath = `/workGroups/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workGroup !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workGroup !== undefined ? workGroup : {})
                : (workGroup || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkGroupsApi - functional programming interface
 * @export
 */
export const WorkGroupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create workGroup
         * @param {NewWorkGroup} newWorkGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkGroup(newWorkGroup: NewWorkGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkGroup>> {
            const localVarAxiosArgs = await WorkGroupsApiAxiosParamCreator(configuration).createWorkGroup(newWorkGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete workGroup by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkGroupByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkGroup>> {
            const localVarAxiosArgs = await WorkGroupsApiAxiosParamCreator(configuration).deleteWorkGroupByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workGroup by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkGroupbyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkGroup>> {
            const localVarAxiosArgs = await WorkGroupsApiAxiosParamCreator(configuration).getWorkGroupbyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workGroups
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkGroups(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedWorkGroupList>> {
            const localVarAxiosArgs = await WorkGroupsApiAxiosParamCreator(configuration).getWorkGroups(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update workGroup by uuid
         * @param {string} uuid 
         * @param {WorkGroup} workGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkGroupByUuid(uuid: string, workGroup: WorkGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkGroup>> {
            const localVarAxiosArgs = await WorkGroupsApiAxiosParamCreator(configuration).updateWorkGroupByUuid(uuid, workGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkGroupsApi - factory interface
 * @export
 */
export const WorkGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create workGroup
         * @param {NewWorkGroup} newWorkGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkGroup(newWorkGroup: NewWorkGroup, options?: any): AxiosPromise<EnvelopedWorkGroup> {
            return WorkGroupsApiFp(configuration).createWorkGroup(newWorkGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete workGroup by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkGroupByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWorkGroup> {
            return WorkGroupsApiFp(configuration).deleteWorkGroupByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workGroup by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkGroupbyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWorkGroup> {
            return WorkGroupsApiFp(configuration).getWorkGroupbyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workGroups
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkGroups(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedWorkGroupList> {
            return WorkGroupsApiFp(configuration).getWorkGroups(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update workGroup by uuid
         * @param {string} uuid 
         * @param {WorkGroup} workGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkGroupByUuid(uuid: string, workGroup: WorkGroup, options?: any): AxiosPromise<EnvelopedWorkGroup> {
            return WorkGroupsApiFp(configuration).updateWorkGroupByUuid(uuid, workGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkGroupsApi - object-oriented interface
 * @export
 * @class WorkGroupsApi
 * @extends {BaseAPI}
 */
export class WorkGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create workGroup
     * @param {NewWorkGroup} newWorkGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkGroupsApi
     */
    public createWorkGroup(newWorkGroup: NewWorkGroup, options?: any) {
        return WorkGroupsApiFp(this.configuration).createWorkGroup(newWorkGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete workGroup by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkGroupsApi
     */
    public deleteWorkGroupByUuid(uuid: string, options?: any) {
        return WorkGroupsApiFp(this.configuration).deleteWorkGroupByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workGroup by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkGroupsApi
     */
    public getWorkGroupbyUuid(uuid: string, options?: any) {
        return WorkGroupsApiFp(this.configuration).getWorkGroupbyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workGroups
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkGroupsApi
     */
    public getWorkGroups(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return WorkGroupsApiFp(this.configuration).getWorkGroups(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update workGroup by uuid
     * @param {string} uuid 
     * @param {WorkGroup} workGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkGroupsApi
     */
    public updateWorkGroupByUuid(uuid: string, workGroup: WorkGroup, options?: any) {
        return WorkGroupsApiFp(this.configuration).updateWorkGroupByUuid(uuid, workGroup, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkNamesApi - axios parameter creator
 * @export
 */
export const WorkNamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create workName
         * @param {NewWorkName} newWorkName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkName: async (newWorkName: NewWorkName, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newWorkName' is not null or undefined
            if (newWorkName === null || newWorkName === undefined) {
                throw new RequiredError('newWorkName','Required parameter newWorkName was null or undefined when calling createWorkName.');
            }
            const localVarPath = `/workNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newWorkName !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newWorkName !== undefined ? newWorkName : {})
                : (newWorkName || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete workName by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkNameByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteWorkNameByUuid.');
            }
            const localVarPath = `/workNames/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workName by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkNamebyUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getWorkNamebyUuid.');
            }
            const localVarPath = `/workNames/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workNames
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkNames: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update workName by uuid
         * @param {string} uuid 
         * @param {WorkName} workName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkNameByUuid: async (uuid: string, workName: WorkName, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateWorkNameByUuid.');
            }
            // verify required parameter 'workName' is not null or undefined
            if (workName === null || workName === undefined) {
                throw new RequiredError('workName','Required parameter workName was null or undefined when calling updateWorkNameByUuid.');
            }
            const localVarPath = `/workNames/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof workName !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(workName !== undefined ? workName : {})
                : (workName || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkNamesApi - functional programming interface
 * @export
 */
export const WorkNamesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create workName
         * @param {NewWorkName} newWorkName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkName(newWorkName: NewWorkName, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkName>> {
            const localVarAxiosArgs = await WorkNamesApiAxiosParamCreator(configuration).createWorkName(newWorkName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete workName by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkNameByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkName>> {
            const localVarAxiosArgs = await WorkNamesApiAxiosParamCreator(configuration).deleteWorkNameByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workName by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkNamebyUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkName>> {
            const localVarAxiosArgs = await WorkNamesApiAxiosParamCreator(configuration).getWorkNamebyUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workNames
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkNames(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedWorkNameList>> {
            const localVarAxiosArgs = await WorkNamesApiAxiosParamCreator(configuration).getWorkNames(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update workName by uuid
         * @param {string} uuid 
         * @param {WorkName} workName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkNameByUuid(uuid: string, workName: WorkName, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWorkName>> {
            const localVarAxiosArgs = await WorkNamesApiAxiosParamCreator(configuration).updateWorkNameByUuid(uuid, workName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkNamesApi - factory interface
 * @export
 */
export const WorkNamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create workName
         * @param {NewWorkName} newWorkName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkName(newWorkName: NewWorkName, options?: any): AxiosPromise<EnvelopedWorkName> {
            return WorkNamesApiFp(configuration).createWorkName(newWorkName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete workName by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkNameByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWorkName> {
            return WorkNamesApiFp(configuration).deleteWorkNameByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workName by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkNamebyUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWorkName> {
            return WorkNamesApiFp(configuration).getWorkNamebyUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workNames
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkNames(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedWorkNameList> {
            return WorkNamesApiFp(configuration).getWorkNames(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update workName by uuid
         * @param {string} uuid 
         * @param {WorkName} workName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkNameByUuid(uuid: string, workName: WorkName, options?: any): AxiosPromise<EnvelopedWorkName> {
            return WorkNamesApiFp(configuration).updateWorkNameByUuid(uuid, workName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkNamesApi - object-oriented interface
 * @export
 * @class WorkNamesApi
 * @extends {BaseAPI}
 */
export class WorkNamesApi extends BaseAPI {
    /**
     * 
     * @summary Create workName
     * @param {NewWorkName} newWorkName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkNamesApi
     */
    public createWorkName(newWorkName: NewWorkName, options?: any) {
        return WorkNamesApiFp(this.configuration).createWorkName(newWorkName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete workName by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkNamesApi
     */
    public deleteWorkNameByUuid(uuid: string, options?: any) {
        return WorkNamesApiFp(this.configuration).deleteWorkNameByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workName by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkNamesApi
     */
    public getWorkNamebyUuid(uuid: string, options?: any) {
        return WorkNamesApiFp(this.configuration).getWorkNamebyUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workNames
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkNamesApi
     */
    public getWorkNames(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return WorkNamesApiFp(this.configuration).getWorkNames(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update workName by uuid
     * @param {string} uuid 
     * @param {WorkName} workName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkNamesApi
     */
    public updateWorkNameByUuid(uuid: string, workName: WorkName, options?: any) {
        return WorkNamesApiFp(this.configuration).updateWorkNameByUuid(uuid, workName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorksApi - axios parameter creator
 * @export
 */
export const WorksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create work
         * @param {NewWork} newWork 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork: async (newWork: NewWork, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newWork' is not null or undefined
            if (newWork === null || newWork === undefined) {
                throw new RequiredError('newWork','Required parameter newWork was null or undefined when calling createWork.');
            }
            const localVarPath = `/works`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newWork !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newWork !== undefined ? newWork : {})
                : (newWork || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete work by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkByUuid: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteWorkByUuid.');
            }
            const localVarPath = `/works/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get work by uuid
         * @param {string} uuid 
         * @param {boolean} [includeCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkbyUuid: async (uuid: string, includeCompleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getWorkbyUuid.');
            }
            const localVarPath = `/works/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (includeCompleted !== undefined) {
                localVarQueryParameter['includeCompleted'] = includeCompleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get works
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorks: async (limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/works`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update work by uuid
         * @param {string} uuid 
         * @param {Work} work 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkByUuid: async (uuid: string, work: Work, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateWorkByUuid.');
            }
            // verify required parameter 'work' is not null or undefined
            if (work === null || work === undefined) {
                throw new RequiredError('work','Required parameter work was null or undefined when calling updateWorkByUuid.');
            }
            const localVarPath = `/works/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization_header required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof work !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(work !== undefined ? work : {})
                : (work || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorksApi - functional programming interface
 * @export
 */
export const WorksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create work
         * @param {NewWork} newWork 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWork(newWork: NewWork, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWork>> {
            const localVarAxiosArgs = await WorksApiAxiosParamCreator(configuration).createWork(newWork, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete work by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkByUuid(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWork>> {
            const localVarAxiosArgs = await WorksApiAxiosParamCreator(configuration).deleteWorkByUuid(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get work by uuid
         * @param {string} uuid 
         * @param {boolean} [includeCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkbyUuid(uuid: string, includeCompleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWork>> {
            const localVarAxiosArgs = await WorksApiAxiosParamCreator(configuration).getWorkbyUuid(uuid, includeCompleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get works
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorks(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedPaginatedWorkList>> {
            const localVarAxiosArgs = await WorksApiAxiosParamCreator(configuration).getWorks(limit, offset, direction, column, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update work by uuid
         * @param {string} uuid 
         * @param {Work} work 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkByUuid(uuid: string, work: Work, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvelopedWork>> {
            const localVarAxiosArgs = await WorksApiAxiosParamCreator(configuration).updateWorkByUuid(uuid, work, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorksApi - factory interface
 * @export
 */
export const WorksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create work
         * @param {NewWork} newWork 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(newWork: NewWork, options?: any): AxiosPromise<EnvelopedWork> {
            return WorksApiFp(configuration).createWork(newWork, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete work by uuid
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkByUuid(uuid: string, options?: any): AxiosPromise<EnvelopedWork> {
            return WorksApiFp(configuration).deleteWorkByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get work by uuid
         * @param {string} uuid 
         * @param {boolean} [includeCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkbyUuid(uuid: string, includeCompleted?: boolean, options?: any): AxiosPromise<EnvelopedWork> {
            return WorksApiFp(configuration).getWorkbyUuid(uuid, includeCompleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get works
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {SortDirection} [direction] 
         * @param {string} [column] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorks(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any): AxiosPromise<EnvelopedPaginatedWorkList> {
            return WorksApiFp(configuration).getWorks(limit, offset, direction, column, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update work by uuid
         * @param {string} uuid 
         * @param {Work} work 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkByUuid(uuid: string, work: Work, options?: any): AxiosPromise<EnvelopedWork> {
            return WorksApiFp(configuration).updateWorkByUuid(uuid, work, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorksApi - object-oriented interface
 * @export
 * @class WorksApi
 * @extends {BaseAPI}
 */
export class WorksApi extends BaseAPI {
    /**
     * 
     * @summary Create work
     * @param {NewWork} newWork 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksApi
     */
    public createWork(newWork: NewWork, options?: any) {
        return WorksApiFp(this.configuration).createWork(newWork, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete work by uuid
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksApi
     */
    public deleteWorkByUuid(uuid: string, options?: any) {
        return WorksApiFp(this.configuration).deleteWorkByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get work by uuid
     * @param {string} uuid 
     * @param {boolean} [includeCompleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksApi
     */
    public getWorkbyUuid(uuid: string, includeCompleted?: boolean, options?: any) {
        return WorksApiFp(this.configuration).getWorkbyUuid(uuid, includeCompleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get works
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {SortDirection} [direction] 
     * @param {string} [column] 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksApi
     */
    public getWorks(limit?: number, offset?: number, direction?: SortDirection, column?: string, includeDeleted?: boolean, options?: any) {
        return WorksApiFp(this.configuration).getWorks(limit, offset, direction, column, includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update work by uuid
     * @param {string} uuid 
     * @param {Work} work 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksApi
     */
    public updateWorkByUuid(uuid: string, work: Work, options?: any) {
        return WorksApiFp(this.configuration).updateWorkByUuid(uuid, work, options).then((request) => request(this.axios, this.basePath));
    }
}


